import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  picker: {
    marginTop: '8px',
    '&:last-child': {
      marginLeft: '8px',
    },
  },
}));

export default function DatePicker({ onStartDateChange, onEndDateChange, startDate, endDate }) {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        className={classes.picker}
        inputVariant='outlined'
        disableToolbar
        variant='inline'
        format='MM/dd/yyyy'
        margin='normal'
        id='start-date-picker'
        autoOk
        label='Start Date'
        value={startDate}
        onChange={onStartDateChange}
        KeyboardButtonProps={{
          'aria-label': 'start date',
        }}
      />
      <KeyboardDatePicker
        className={classes.picker}
        disableToolbar
        inputVariant='outlined'
        variant='inline'
        format='MM/dd/yyyy'
        margin='normal'
        id='end-date-picker'
        autoOk
        label='End Date'
        value={endDate}
        onChange={onEndDateChange}
        KeyboardButtonProps={{
          'aria-label': 'end date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
