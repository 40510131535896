import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Error from '../../components/Error/Error';
import Loading from '../../components/Loading/Loading';
import ImageInput from '../../components/ImageInput/ImageInput';
import { Context as UserContext } from '../../context/UserContext';
import { Context as ReviewContext } from '../../context/ReviewContext';
import { Context as OffersContext } from '../../context/OffersContext';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(3),
  },
  progress: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  products: {
    display: 'flex',
  },
  productsIcon: {
    alignSelf: 'center',
    marginLeft: theme.spacing(1),
  },
}));

export default function ReviewPage({ onRender }) {
  // VARS
  const classes = useStyles();
  const { id } = useParams();

  // STATE
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [location, setLocation] = useState('');
  const [imageSrc, setImageSrc] = useState('');
  const [offer, setOffer] = useState({
    name: '',
    id: '',
  });

  // CONTEXTS
  const {
    state: { user },
  } = useContext(UserContext);

  const {
    state: { review, loading: reviewLoading, error: reviewError },
    loadReview,
    updateReview,
  } = useContext(ReviewContext);

  const {
    state: { offers, loading: offersLoading, error: offersError },
    loadOffers,
  } = useContext(OffersContext);

  // EFFECTS
  useEffect(() => {
    if (user) {
      loadReview(id);
      loadOffers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (review) {
      onRender(`Review - ${review.title}`);
      // Set values after review is loaded
      setName(review.name);
      setTitle(review.title);
      setText(review.text);
      setLocation(review.location);
      setImageSrc(review.imageSrc);
      setOffer(review.offer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [review]);

  if (reviewError || offersError) {
    return <Error error={reviewError || offersError} />;
  }

  if (offersLoading || offersLoading) {
    return <Loading />;
  }

  if (!review) {
    return null;
  }

  function handleReviewUpdate(update) {
    updateReview(id, update);
  }

  function handleOfferChange(e) {
    const offer = offers.find((item) => item._id === e.target.value);

    setOffer(offer);
    updateReview(id, {
      offer: {
        id: offer._id,
        name: offer.name,
      },
    });
  }

  return (
    <Container maxWidth='lg' className={classes.container}>
      {reviewLoading && <LinearProgress className={classes.progress} />}
      <Paper className={classes.paper}>
        <TextField
          label='Id'
          value={review._id}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
          className={classes.textField}
        />
        <FormControl className={classes.textField} fullWidth>
          <InputLabel shrink id='demo-simple-select-placeholder-label-label'>
            Offer
          </InputLabel>
          <Select labelId='offer-select' id='offer-select' value={offer.id} onChange={handleOfferChange}>
            {offers.map((item, index) => (
              <MenuItem key={index} value={item._id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label='Title'
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          onBlur={() =>
            handleReviewUpdate({
              title,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <TextField
          label='Text'
          value={text}
          onChange={(e) => setText(e.target.value)}
          onBlur={() =>
            handleReviewUpdate({
              text,
            })
          }
          multiline
          rows={2}
          fullWidth
          className={classes.textField}
        />
        <TextField
          label='Name'
          value={name}
          onChange={(e) => setName(e.target.value)}
          onBlur={() =>
            handleReviewUpdate({
              name,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <TextField
          label='Location'
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          onBlur={() =>
            handleReviewUpdate({
              location,
            })
          }
          fullWidth
          className={classes.textField}
        />
        <ImageInput
          label='Image source url'
          value={imageSrc}
          onChange={(e) => setImageSrc(e.target.value)}
          onBlur={(e) =>
            handleReviewUpdate({
              imageSrc,
            })
          }
        />
      </Paper>
    </Container>
  );
}

ReviewPage.propTypes = {
  onRender: PropTypes.func,
};
