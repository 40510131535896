import React, { useContext, useEffect, useState } from 'react';
import { CSVDownload } from 'react-csv';
import moment from 'moment';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { Context as OrdersContext } from '../../context/OrdersContext';
import { Context as OffersContext } from '../../context/OffersContext';
import { Context as AnalyticsContext } from '../../context/AnalyticsContext';
import { Context as AffIdsContext } from '../../context/AffIdsContext';
import Error from '../../components/Error/Error';
import DatePicker from '../../components/DatePicker/DatePicker';
import OrdersTable from './OrdersTable';
import Analytics from './Analytics';
import { CircularProgress, IconButton, LinearProgress } from '@material-ui/core';
import { api } from '../../api/api';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  ordersHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  title: {
    flex: 1,
  },
  filters: {
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    '&:last-child': {
      marginRight: 0,
    },
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
  },
  checkbox: {
    marginTop: theme.spacing(1),
  },
  dateRangeSelector: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(3),
  },
  searchByOrder: {
    alignSelf: 'flex-end',
  },
  apply: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const orderStatuses = ['Completed', 'Not completed', 'Processed', 'Not processed', 'Successful', 'Declined'];

export default function HomePage({ onRender }) {
  const classes = useStyles();
  const history = useHistory();
  const initStartDate = moment().set({ hour: 0, minute: 0, second: 0 }).toISOString();
  const initEndDate = moment().set({ hour: 23, minute: 59, second: 59 }).toISOString();
  // eslint-disable-next-line no-restricted-globals
  const parsedQuery = queryString.parse(location.search);

  const [exportReady, setExportReady] = useState(false);
  const [exportingOrders, setExportingOrders] = useState(false);
  const [exportedOrders, setExportedOrders] = useState([]);
  const [applyButtonVisible, setApplyButtonVisible] = useState(false);
  const [pagination, setPagination] = useState({
    page: 0,
    limit: 25,
  });

  const [selectedRange, setSelectedRange] = useState(parsedQuery.range || 'Today');

  const [filters, setFilters] = useState({
    startDate: parsedQuery.startDate || initStartDate,
    endDate: parsedQuery.endDate || initEndDate,
    offer: '',
    affId: '',
    status: '',
    noDuplicates: false,
  });

  // Context
  const {
    state: { orders, loading: ordersLoading, error: ordersError },
    loadOrders,
    deleteOrder,
  } = useContext(OrdersContext);

  const {
    state: { offers, error: offersError },
    loadOffers,
  } = useContext(OffersContext);

  const {
    state: { affIds, error: affIdsError },
    loadAffIds,
  } = useContext(AffIdsContext);

  const {
    state: { analytics, loading: analyticsLoading, error: analyticsError },
    loadAnalytics,
  } = useContext(AnalyticsContext);

  // Effects
  useEffect(() => {
    onRender('Orders');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  useEffect(() => {
    loadOffers();
    loadAffIds(filters);
    loadAnalytics(filters);
    loadOrders({
      ...pagination,
      ...filters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function exportOrders() {
    setExportReady(false);
    setExportingOrders(true);

    try {
      const response = await api.get('/exportOrders', {
        params: filters,
      });

      setExportedOrders(response.data);
      setExportingOrders(false);
      setExportReady(true);
    } catch (error) {
      setExportingOrders(false);
      setExportReady(false);
    }
  }

  function updateQueryParams() {
    const searchString = queryString.stringify({
      startDate: filters.startDate,
      endDate: filters.endDate,
      range: selectedRange,
    });

    history.push({
      search: searchString,
    });
  }

  function handlePaginationChange(newPagination) {
    setPagination(newPagination);
    loadOrders({
      ...newPagination,
      ...filters,
    });
  }

  function handleFilterChange(name, value) {
    const newFilters = { ...filters, [name]: value };
    const newPagination = {
      page: 0,
      limit: 25,
    };

    setFilters(newFilters);
    setPagination(newPagination);
    setApplyButtonVisible(true);
  }

  function applyFilters() {
    updateQueryParams();

    loadAnalytics(filters);
    loadOrders({
      ...pagination,
      ...filters,
    });
    loadAffIds(filters);

    setApplyButtonVisible(false);
  }

  function renderOfferSelect() {
    function handleChange(e) {
      handleFilterChange('offer', e.target.value);
    }

    return (
      <FormControl className={classes.formControl}>
        <InputLabel id='offer-search' style={{ backgroundColor: '#fafafa' }}>
          Offer
        </InputLabel>
        <Select labelId='offer-search' value={filters.offer} onChange={handleChange}>
          <MenuItem value=''>All</MenuItem>
          {offers.map((item, index) => (
            <MenuItem key={index} value={item.name}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  function renderAffSelect() {
    function handleChange(e) {
      handleFilterChange('affId', e.target.value);
    }

    return (
      <FormControl className={classes.formControl}>
        <InputLabel id='affiliate' style={{ backgroundColor: '#fafafa' }}>
          Affiliate
        </InputLabel>
        <Select labelId='affiliate' value={filters.affId} onChange={handleChange}>
          <MenuItem value=''>All</MenuItem>
          {affIds.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  function renderStatusSelect() {
    function handleChange(e) {
      handleFilterChange('status', e.target.value);
    }

    return (
      <FormControl className={classes.formControl}>
        <InputLabel id='status' style={{ backgroundColor: '#fafafa' }}>
          Order status
        </InputLabel>
        <Select labelId='status' value={filters.status} onChange={handleChange}>
          <MenuItem value=''>All</MenuItem>
          {orderStatuses.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  function renderDuplicatesFilter() {
    function handleChange(e) {
      handleFilterChange('noDuplicates', e.target.checked);
    }

    return (
      <FormControlLabel
        control={
          <Switch
            checked={filters.noDuplicates}
            onChange={handleChange}
            name='duplicates-filter'
            color='primary'
          />
        }
        label='Filter duplicates'
      />
    );
  }

  function renderRangeSelector() {
    const ranges = [
      'Today',
      'Yesterday',
      'Last 7 days',
      'Last 30 days',
      'This month',
      'Last month',
      'Custom',
    ];

    function handleChange(e) {
      setSelectedRange(e.target.value);

      let startDate, endDate;

      switch (e.target.value) {
        case 'Today':
          startDate = moment().set({ hour: 0, minute: 0, second: 0 }).toISOString();
          endDate = moment().set({ hour: 23, minute: 59, second: 59 }).toISOString();
          break;
        case 'Yesterday':
          startDate = moment().subtract(1, 'day').set({ hour: 0, minute: 0, second: 0 }).toISOString();
          endDate = moment().subtract(1, 'day').set({ hour: 23, minute: 59, second: 59 }).toISOString();
          break;
        case 'Last 7 days':
          startDate = moment().subtract(6, 'days').set({ hour: 0, minute: 0, second: 0 }).toISOString();
          endDate = moment().set({ hour: 23, minute: 59, second: 59 }).toISOString();
          break;
        case 'Last 30 days':
          startDate = moment().subtract(29, 'days').set({ hour: 0, minute: 0, second: 0 }).toISOString();
          endDate = moment().set({ hour: 23, minute: 59, second: 59 }).toISOString();
          break;
        case 'This month':
          startDate = moment().startOf('month').set({ hour: 0, minute: 0, second: 0 }).toISOString();
          endDate = moment().endOf('month').set({ hour: 23, minute: 59, second: 59 }).toISOString();
          break;
        case 'Last month':
          startDate = moment()
            .subtract(1, 'month')
            .startOf('month')
            .set({ hour: 0, minute: 0, second: 0 })
            .toISOString();
          endDate = moment()
            .subtract(1, 'month')
            .endOf('month')
            .set({ hour: 23, minute: 59, second: 59 })
            .toISOString();
          break;
        case 'Custom':
          startDate = initStartDate;
          endDate = initEndDate;
          break;
        default:
          break;
      }

      setFilters({
        ...filters,
        startDate,
        endDate,
      });

      const newPagination = {
        page: 0,
        limit: 25,
      };
      setPagination(newPagination);
      setApplyButtonVisible(true);
    }

    return (
      <FormControl className={classes.formControl}>
        <InputLabel id='range' style={{ backgroundColor: '#fafafa' }}>
          Date Range
        </InputLabel>
        <Select labelId='range' value={selectedRange} onChange={handleChange}>
          {ranges.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  function renderPageContent() {
    if (ordersError || offersError || analyticsError || affIdsError) {
      return <Error error={ordersError || offersError || analyticsError || affIdsError} />;
    }

    if (ordersLoading) {
      return <LinearProgress />;
    }

    return (
      <Box>
        <Analytics data={analytics} loading={analyticsLoading} />
        <OrdersTable
          pagination={pagination}
          ordersTotal={analytics.ordersTotal}
          onPaginationChange={handlePaginationChange}
          orders={orders}
          onDeleteOrder={deleteOrder}
        />
      </Box>
    );
  }

  return (
    <Container className={classes.container}>
      <Box className={classes.dateRangeSelector}>
        {renderRangeSelector()}
        {selectedRange === 'Custom' && (
          <DatePicker
            maxDate={initEndDate}
            startDate={filters.startDate}
            endDate={filters.endDate}
            onStartDateChange={(date) => handleFilterChange('startDate', date)}
            onEndDateChange={(date) => handleFilterChange('endDate', date)}
          />
        )}
      </Box>
      <Box className={classes.ordersHeader}>
        <Typography variant='h6' component='h5' className={classes.title}>
          Orders ({analytics.ordersTotal})
          <IconButton
            disabled={true || exportingOrders}
            variant='contained'
            color='primary'
            component='span'
            onClick={() => exportOrders()}
          >
            {exportingOrders ? <CircularProgress size={20} /> : <SaveAltIcon />}
          </IconButton>
          {exportReady && <CSVDownload data={exportedOrders}></CSVDownload>}
        </Typography>
        <FormGroup row className={classes.filters}>
          {renderDuplicatesFilter()}
          {renderOfferSelect()}
          {renderAffSelect()}
          {renderStatusSelect()}
        </FormGroup>
      </Box>
      {applyButtonVisible && (
        <Box className={classes.apply}>
          <Button
            variant='contained'
            color='primary'
            style={{
              width: '200px',
              marginLeft: '15px',
            }}
            onClick={() => applyFilters()}
          >
            Apply Filters
          </Button>
        </Box>
      )}
      {renderPageContent()}
    </Container>
  );
}
